'use client'

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import type { Program, ProgramEnrollment } from '@/types'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useTranslation } from 'react-i18next'

interface ProgramCardProps {
	program: Program
	enrollment?: ProgramEnrollment
}

const ProgramCard: React.FC<ProgramCardProps> = ({ program, enrollment }) => {
	const { t } = useTranslation('programs')
	const router = useRouter()

	return (
		<Card
			key={program.id}
			className='w-full cursor-pointer hover:shadow-md border-b-2 border-card shadow-sm rounded-md'
			onClick={() => router.push(`/dashboard/programs/${program.id}`)}
		>
			<CardHeader>
				<CardTitle className='text-xl font-semibold'>{program.program_name}</CardTitle>
				<CardDescription>{program.goal}</CardDescription>
			</CardHeader>
			<CardContent className='text-card-foreground'>
				<div
					className='leading-normal'
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={{
						__html:
							program.program_description && program.program_description.length > 200
								? `${program.program_description.substring(0, 200)}...`
								: program.program_description || '',
					}}
				/>
			</CardContent>
			<CardFooter>
				<p className='text-sm'>
					{t('duration')}: {program.duration_in_weeks} {t('weeks')}
				</p>
			</CardFooter>
		</Card>
	)
}

export { ProgramCard }
