import type { GenerateTrainingPlanRequest, GenerateTrainingPlanResponse } from '@/types' // Ajustar para '@/types'
import api from './api'

export const generateTrainingPlan = async (
	trainingPreferences: GenerateTrainingPlanRequest,
): Promise<GenerateTrainingPlanResponse> => {
	try {
		const response = await api.post<GenerateTrainingPlanResponse>('/generate-plan', {
			trainingPreferences,
		})
		return response.data
	} catch (error: any) {
		console.error('Erro na requisição para gerar plano de treino:', error)
		throw new Error(error?.response?.data?.message || 'Erro ao gerar plano de treino')
	}
}
