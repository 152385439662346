'use client'
import type { MultiSelectProps } from '@/types/types'
import { getSelectStyles } from '@/utils/selectStyles'
import { useTheme } from 'next-themes'
import React from 'react'
import { useController } from 'react-hook-form'
import Select from 'react-select'

export function MultiSelect({ control, name, options, placeholder }: MultiSelectProps) {
	const { field } = useController({ control, name })
	const { theme = 'system' } = useTheme()

	return (
		<Select
			isMulti
			value={options.filter((option) => (field.value || []).includes(option.value))}
			onChange={(selectedOptions) => {
				const values = selectedOptions.map((option) => option.value)
				field.onChange(values)
			}}
			options={options}
			placeholder={placeholder}
			styles={getSelectStyles(theme)}
		/>
	)
}
