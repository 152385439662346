// src/utils/selectStyles.ts
export function getSelectStyles(theme: string) {
	return {
		control: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(223, 23%, 38%)' : 'hsl(219, 47%, 90%)',
			borderColor: theme === 'dark' ? 'hsl(210, 17%, 96%)' : 'hsl(0, 0%, 84.7%)',
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		menu: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(223, 16%, 20%)' : 'hsl(242.3, 43%, 95%)',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isFocused
				? 'hsl(219.7, 77.1%, 80.6%)'
				: theme === 'dark'
					? 'hsl(223, 16%, 20%)'
					: 'hsl(242.3, 43%, 95%)',
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		multiValue: (provided: any) => ({
			...provided,
			backgroundColor: 'hsl(219.7, 77.1%, 80.6%)',
		}),
		multiValueLabel: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		placeholder: (provided: any) => ({
			...provided,

			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		input: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
	}
}
