// src/components/program-form.tsx

'use client'

import { MultiSelect, ProgramProtocolsTable } from '@/components'
import { CustomInput } from '@/components'
import TipTapEditor from '@/components/tiptap-editor'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Title } from '@/components/ui/title'
import { useToast } from '@/components/ui/use-toast'
import { useProgramSchema } from '@/lib'
import {
	createProgram,
	deleteProgramProtocol,
	enrollClientsInProgram,
	fetchProtocols,
	fetchUsers,
	linkProtocolToProgram,
	removeClientsFromProgram,
	updateProgram,
} from '@/services/api'
import type { AppUser, ProgramFormProps, ProgramProtocol, Protocol } from '@/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft, X } from 'lucide-react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import type { z } from 'zod'
import ProtocolForm from './protocol-form'

const ProgramForm: React.FC<ProgramFormProps> = ({ initialProgram }) => {
	const { data: session, status } = useSession()
	const isAuthenticated = status === 'authenticated'
	const user = session?.user
	const { toast } = useToast()
	const { t } = useTranslation('programs')
	const router = useRouter()

	const [clients, setClients] = useState<AppUser[]>([])
	const [programId, setProgramId] = useState<number | null>(initialProgram ? initialProgram.id : null)
	const [originalClientIds, setOriginalClientIds] = useState<string[]>([])
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isProgramSaved, setIsProgramSaved] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [protocols, setProtocols] = useState<ProgramProtocol[]>([])
	const [currentProtocol, setCurrentProtocol] = useState<Protocol | undefined>(undefined)

	const programSchema = useProgramSchema()

	const form = useForm<z.infer<typeof programSchema>>({
		resolver: zodResolver(programSchema),
		defaultValues: initialProgram
			? {
					...initialProgram,
					clientIds: initialProgram.enrollments
						? initialProgram.enrollments.map((enrollment) => enrollment.userId.toString())
						: [],
					program_description: initialProgram.program_description ?? '',
				}
			: {
					program_name: '',
					goal: '',
					duration_in_weeks: 0,
					program_description: '',
					clientIds: [],
				},
		mode: 'onChange',
	})

	useEffect(() => {
		const subscription = form.watch(() => {
			setIsProgramSaved(false)
		})
		return () => subscription.unsubscribe()
	}, [form])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (programId) {
			fetchProtocols(programId).then(setProtocols).catch(console.error)
		}
	}, [programId, isAuthenticated])

	useEffect(() => {
		if (initialProgram?.enrollments) {
			const clientIds = initialProgram.enrollments.map((enrollment) => enrollment.userId.toString())
			setOriginalClientIds(clientIds)
		}
	}, [initialProgram])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (user && (user.role === 'COACH' || user.role === 'ADMIN')) {
			fetchUsers({ role: 'CLIENT', status: 'ACTIVE' })
				.then(setClients)
				.catch((error) => {
					console.error('Error fetching clients:', error)
				})
		}
	}, [user, isAuthenticated])

	const openModal = (protocol?: Protocol) => {
		setCurrentProtocol(protocol)
		setModalIsOpen(true)
	}

	const closeModal = () => {
		setModalIsOpen(false)
	}

	const onProtocolSaved = async (protocol: Protocol) => {
		if (!programId) return
		try {
			const newProgramProtocol = await linkProtocolToProgram(programId, protocol.id)

			setProtocols((prevProtocols) => {
				const existingIndex = prevProtocols.findIndex((p) => p.protocol.id === protocol.id)
				if (existingIndex !== -1) {
					// Update existing protocol
					const updatedProtocols = [...prevProtocols]
					updatedProtocols[existingIndex] = {
						...prevProtocols[existingIndex],
						...newProgramProtocol,
						protocol,
					}
					return updatedProtocols
				}
				// Add new protocol
				return [
					...prevProtocols,
					{
						...newProgramProtocol,
						protocol,
					},
				]
			})
		} catch (error) {
			console.error('Error linking protocol to program:', error)
		}
	}

	const onSubmitProgram: SubmitHandler<z.infer<typeof programSchema>> = async (data) => {
		if (isSubmitting) return
		setIsSubmitting(true)

		try {
			const { clientIds = [], ...restData } = data // Ensure clientIds is an empty array if undefined

			const programData = {
				...restData,
				private: Boolean(clientIds && clientIds.length > 0),
			}

			let createdProgram = null

			if (programId) {
				createdProgram = await updateProgram({
					...programData,
					id: programId,
				})
			} else {
				createdProgram = await createProgram(programData)
				setProgramId(createdProgram.id)
			}

			if (clientIds.length > 0) {
				await enrollClientsInProgram(
					createdProgram.id,
					clientIds.map((id) => Number.parseInt(id, 10)),
				)
			}

			const removedClientIds = originalClientIds.filter((originalId) => !clientIds.includes(originalId))

			if (removedClientIds.length > 0) {
				await removeClientsFromProgram(
					createdProgram.id,
					removedClientIds.map((id) => Number.parseInt(id, 10)),
				)
			}

			setIsProgramSaved(true)
			toast({ description: t('program_saved_success'), variant: 'success' })
		} catch (error: any) {
			console.error('Error saving program:', error)
			if (error.response) {
				console.error('Error response data:', error.response.data)
			}
			toast({ description: t('error_saving_program'), variant: 'destructive' })
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleDeleteProtocol = async (protocol: ProgramProtocol) => {
		if (!protocol || !programId) return
		try {
			await deleteProgramProtocol(protocol.id)
			setProtocols((prevProtocols) => prevProtocols.filter((p) => p.id !== protocol.id))
			toast({ description: t('delete_protocol_success'), variant: 'success' })
		} catch (error) {
			toast({
				description: t('delete_protocol_error'),
				variant: 'destructive',
			})
		}
	}

	return (
		<div className='container mx-auto max-w-3xl p-4'>
			{initialProgram && (
				<div className='flex items-center mb-4'>
					<ArrowLeft className='h-6 w-6 mr-2 cursor-pointer' onClick={() => router.back()} />
					<Title>{initialProgram.program_name}</Title>
				</div>
			)}
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmitProgram)} className='space-y-4'>
					<CustomInput
						control={form.control}
						name='program_name'
						label={t('program_name')}
						placeholder={t('program_name')}
					/>

					<FormField
						control={form.control}
						name='program_description'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<TipTapEditor content={field.value ?? ''} onChange={field.onChange} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<CustomInput control={form.control} name='goal' label={t('goal')} placeholder={t('goal')} />
					<CustomInput
						control={form.control}
						name='duration_in_weeks'
						label={t('duration_in_weeks')}
						placeholder={t('duration_in_weeks')}
						type='number'
					/>

					{user && (user.role === 'COACH' || user.role === 'ADMIN') && (
						<FormField
							control={form.control}
							name='clientIds'
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<MultiSelect
											control={form.control}
											name='clientIds'
											options={clients.map((client) => ({
												value: client.id.toString(),
												label: `${client.name} (${client.username})`,
											}))}
											placeholder={t('select_clients')}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					)}

					<div className='w-full flex justify-end'>
						<Button
							type='submit'
							disabled={isProgramSaved || !form.formState.isDirty || isSubmitting}
							className={isProgramSaved ? 'bg-gray-400' : 'bg-primary'}
						>
							{t('save_program')}
						</Button>
					</div>
				</form>
			</Form>

			{programId && (
				<>
					<div className='w-full flex justify-end items-end py-2'>
						<Button type='button' onClick={() => openModal()} variant='secondary'>
							{t('add_protocol')}
						</Button>
					</div>

					<div className='mt-4 w-full'>
						{protocols.length > 0 && (
							<ProgramProtocolsTable
								protocols={protocols}
								mode='form'
								openModal={openModal}
								openDeleteDialog={handleDeleteProtocol}
							/>
						)}
					</div>

					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						ariaHideApp={false}
						className='fixed inset-0 flex items-center justify-center p-4 my-16'
						overlayClassName='fixed inset-0 bg-black bg-opacity-50 z-30'
					>
						<div className='bg-background rounded-lg shadow-lg w-full max-w-3xl max-h-full flex flex-col'>
							<div className='overflow-y-auto p-6'>
								<div className='flex justify-between items-center ml-4'>
									<h3 className='text-xl font-bold'>{t('protocol')}</h3>
									<Button onClick={closeModal} variant='link' className='text-foreground'>
										<X className='w-6 h-6' size={24} />
									</Button>
								</div>
								<ProtocolForm
									programId={programId}
									initialProtocol={currentProtocol}
									onProtocolSaved={onProtocolSaved}
								/>
							</div>
						</div>
					</Modal>
				</>
			)}
		</div>
	)
}

export { ProgramForm }
