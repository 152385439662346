'use client'

import { ConfirmDialog } from '@/components'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/components/ui/table'
import type { ProgramProtocol } from '@/types'
import { Pencil, Trash2 } from 'lucide-react'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useTranslation } from 'react-i18next'

interface ProgramProtocolsTableProps {
	protocols: ProgramProtocol[]
	mode: 'form' | 'detail'
	openModal?: (protocol: ProgramProtocol['protocol']) => void
	openDeleteDialog?: (protocol: ProgramProtocol) => void
}

export const ProgramProtocolsTable: React.FC<ProgramProtocolsTableProps> = ({
	protocols,
	mode,
	openModal,
	openDeleteDialog,
}) => {
	const router = useRouter()
	const { t } = useTranslation('programs')
	const handleProtocolClick = (protocolId: number) => {
		if (mode === 'detail') {
			router.push(`/protocols/${protocolId}/view`)
		} else {
			router.push(`/protocols/${protocolId}`)
		}
	}

	return (
		<Table>
			<TableHeader />
			<TableBody>
				{protocols.map((programProtocol) => (
					<TableRow key={programProtocol.id}>
						<TableCell>
							<Button
								variant='link'
								className='text-title'
								onClick={() => handleProtocolClick(programProtocol.protocol.id)}
							>
								{programProtocol.protocol.title}
							</Button>
						</TableCell>
						{mode === 'form' && (
							<TableCell className='flex'>
								<Button variant='soft' onClick={() => openModal?.(programProtocol.protocol)}>
									<Pencil className='w-4 h-4 text-blue-500 cursor-pointer' />
								</Button>

								<ConfirmDialog
									trigger={
										<Button variant='soft'>
											<Trash2 className='w-4 h-4 text-red-500 cursor-pointer' />
										</Button>
									}
									confirmText={'Delete'}
									cancelText={'Cancel'}
									title={'Are you sure?'}
									description={t('cannot_undo_program_protocol', {
										protocolTitle: programProtocol.protocol.title,
									})}
									onConfirm={() => openDeleteDialog?.(programProtocol)}
								/>
							</TableCell>
						)}
					</TableRow>
				))}
			</TableBody>
		</Table>
	)
}
