import type { TrainingTechnique } from '@/types'
import api from './api'

// Function to retrieve advanced techniques
export const getTrainingTechniques = async (): Promise<TrainingTechnique[]> => {
	try {
		const response = await api.get<TrainingTechnique[]>('/training-techniques')
		return response.data
	} catch (error: any) {
		console.error('Erro ao recuperar técnicas avançadas:', error)
		throw new Error(error?.response?.data?.message || 'Erro ao recuperar técnicas avançadas')
	}
}
