'use client'
import { useToast } from '@/components/ui/use-toast'
import { createTag, fetchTags } from '@/services/api'
import type { CreatableMultiSelectProps } from '@/types'
import { getSelectStyles } from '@/utils/selectStyles'
import { useSession } from 'next-auth/react'
import { useTheme } from 'next-themes'
import React, { useState, useEffect, forwardRef } from 'react'
import { useController } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'

const CreatableSelectWithRef = forwardRef<any, any>((props, ref) => <CreatableSelect {...props} ref={ref} />)
CreatableSelectWithRef.displayName = 'CreatableSelectWithRef'

export function CreatableMultiSelect({
	control,
	name,
	placeholder = 'Tags',
	selectedTags = [],
}: CreatableMultiSelectProps) {
	const { field } = useController({ control, name })
	const [isLoading, setIsLoading] = useState(true)
	const [options, setOptions] = useState<{ label: string; value: string }[]>([])
	const { toast } = useToast()
	const { data: session, status } = useSession()
	const isAuthenticated = status === 'authenticated'
	const { theme = 'system' } = useTheme()

	useEffect(() => {
		const loadTags = async () => {
			try {
				const tags = await fetchTags()
				setOptions(tags.map((tag) => ({ label: tag.name, value: tag.name })))
				setIsLoading(false)
			} catch (error) {
				console.error('Error fetching tags:', error)
				setIsLoading(false)
			}
		}
		loadTags()
	}, [])

	const handleCreate = async (inputValue: string) => {
		if (!isAuthenticated) {
			toast({
				description: 'You must be logged in to create a tag.',
				variant: 'destructive',
			})
			return
		}

		try {
			const newTag = await createTag(inputValue)
			const newOption = { label: newTag.name, value: newTag.name }
			setOptions((prev) => [...prev, newOption])
			field.onChange([...(field.value || []), newOption.value])
		} catch (error) {
			console.error('Error creating tag:', error)
			toast({ description: 'Failed to create tag', variant: 'destructive' })
		}
	}

	return (
		<CreatableSelectWithRef
			isMulti
			placeholder={placeholder}
			options={options}
			value={selectedTags.map((tag) => ({ value: tag, label: tag }))}
			onChange={(selectedOptions: any[]) => {
				field.onChange(selectedOptions.map((option: any) => option.value))
			}}
			onCreateOption={handleCreate}
			name={name}
			isLoading={isLoading}
			styles={getSelectStyles(theme)}
			ref={field.ref}
		/>
	)
}
