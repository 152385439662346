import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import type { PostData } from '@/types'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

interface IPostProps {
	post: PostData
	isLcp?: boolean
}

const PostCard: React.FC<IPostProps> = ({ post, isLcp = false }) => {
	const { t, i18n } = useTranslation('posts')
	const currentLocale = i18n.language === 'pt-BR' ? ptBR : enUS

	const parsedDate = parseISO(post.createdAt)
	const formattedDate = format(parsedDate, "d 'de' MMMM", {
		locale: currentLocale,
	})
	const timeAgo = formatDistanceToNow(parsedDate, {
		addSuffix: true,
		locale: currentLocale,
	})

	const truncatedDescription =
		post.description.length > 120 ? `${post.description.substring(0, 120)}...` : post.description

	return (
		<div className='flex w-full py-2 sm:px-4'>
			<Card variant='primary' className='mx-auto w-full sm:max-w-md md:max-w-lg lg:max-w-xl rounded-md'>
				<CardHeader className='flex justify-start px-4'>
					<div className='flex gap-2 items-center'>
						<Avatar className='w-10 h-10 sm:w-12 sm:h-12'>
							<AvatarImage
								src={
									typeof post.author.user_avatar === 'string'
										? post.author.user_avatar
										: 'https://github.com/shadcn.png'
								}
							/>
							<AvatarFallback>{post.author.name[0]}</AvatarFallback>
						</Avatar>

						<div className=''>
							<span className='font-semibold'>{post.author.name}</span>
							<br />
							<span className='text-sm text-muted-foreground'>
								{formattedDate} ({timeAgo})
							</span>
						</div>
					</div>
				</CardHeader>
				<CardContent variant='primary' className='pt-0'>
					{typeof post.post_image === 'string' && post.post_image && (
						<Image
							width={576}
							height={592}
							priority={isLcp}
							fetchPriority={isLcp ? 'high' : 'auto'}
							src={post.post_image}
							alt={post.title}
							className='pb-4'
							sizes='(max-width: 768px) 100vw, 600px'
							style={{ objectFit: 'cover' }}
						/>
					)}
					<div className='px-4 pb-3'>
						<div className='flex gap-2 pb-2 flex-wrap text-md'>
							{post.post_tags.length > 0 &&
								post.post_tags.map((tag) => (
									<Badge className='font-light text-sm' key={tag.id}>
										{tag.name}
									</Badge>
								))}
						</div>
						<CardTitle className='py-2'>{post.title}</CardTitle>
						<CardDescription className='mt-2 sm:mt-3 text-sm sm:text-base'>
							{/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
							<p dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
						</CardDescription>
					</div>
				</CardContent>
				<CardFooter className='px-4'>
					<Link href={`/posts/${post.id}`}>
						<span className='text-blue-500 hover:underline text-sm sm:text-base'>{t('read_more')}</span>
					</Link>
				</CardFooter>
			</Card>
		</div>
	)
}

export default PostCard
