import type { AppUser, FetchUsersParams } from '@/types'
import type { AxiosProgressEvent } from 'axios'
import api from './api'

// Fetch user profile
export const fetchUserProfile = async (): Promise<AppUser> => {
	const response = await api.get<AppUser>('/user/profile')
	return response.data
}

// Update user profile (including avatar upload)
export const updateUserProfile = async (
	data: FormData,
	onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<AppUser> => {
	const response = await api.put<AppUser>('/user/profile', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress,
	})

	return response.data
}

// Update user data (status, name, email, role)
export const updateUser = async (userId: number, data: Partial<AppUser>): Promise<void> => {
	await api.put(`/user/${userId}`, data, {
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

// Fetch users with optional parameters
export const fetchUsers = async (params?: FetchUsersParams): Promise<AppUser[]> => {
	const response = await api.get<AppUser[]>('/user', {
		params,
	})
	return response.data
}

// Fetch user by ID
export const fetchUser = async (userId: number): Promise<AppUser> => {
	const response = await api.get<AppUser>(`/user/${userId}`)
	return response.data
}

// Delete a user
export const deleteUser = async (userId: number): Promise<void> => {
	await api.delete(`/user/${userId}`)
}

export const updateUserRole = async (role: 'CLIENT' | 'COACH'): Promise<AppUser> => {
	const response = await api.post('/user/update-role', { role })
	return response.data.user
}
