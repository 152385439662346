import { auth } from '@/auth'

import axios from 'axios'
import { getSession } from 'next-auth/react'

const api = axios.create({
	baseURL: process.env.NEXT_PUBLIC_API_URL,
})

api.interceptors.request.use(
	async (config) => {
		let accessToken: string | undefined

		if (typeof window === 'undefined') {
			// Server side
			const session = await auth()
			accessToken = session?.accessToken || session?.user?.accessToken
		} else {
			// Client Side
			const session = await getSession()
			accessToken = session?.accessToken || session?.user?.accessToken
		}

		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

export default api
