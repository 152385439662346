import type { UserProfile } from '@/types'
import type { AxiosProgressEvent } from 'axios'
import api from './api'

// Fetch user profile
export const fetchUserPhysicalData = async (): Promise<UserProfile | null> => {
	try {
		const response = await api.get<UserProfile>('/user-profile')
		return response.data
	} catch (error: any) {
		if (error.response && error.response.status === 404) {
			// Profile not found
			return null
		}
		// Throw error to be handled by the caller
		throw error
	}
}

// Update or create user profile
export const updateUserPhysicalData = async (
	data: UserProfile,
	onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<void> => {
	await api.post('/user-profile', data, {
		onUploadProgress,
	})
}
