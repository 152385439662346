'use client'

import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useEffect } from 'react'
import { GradientSpinner } from './ui/spinner'

interface WithAuthProps {
	allowedRoles?: string[]
}

function withAuth<P extends object>(WrappedComponent: React.ComponentType<P>, allowedRoles: string[] = []) {
	const WithAuthComponent: React.FC<P & WithAuthProps> = (props) => {
		const { data: session, status } = useSession()
		const router = useRouter()

		const isAuthenticated = status === 'authenticated'
		const isLoading = status === 'loading'
		const userRole = session?.user?.role

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (!isLoading) {
				if (!isAuthenticated) {
					router.push('/login') // Redirects to login page if not authenticated
				} else if (allowedRoles.length > 0 && userRole && !allowedRoles.includes(userRole)) {
					router.push('/unauthorized')
				}
			}
		}, [isAuthenticated, isLoading, userRole, router])

		if (isLoading) {
			return <GradientSpinner />
		}

		if (!isAuthenticated) {
			return null
		}

		if (allowedRoles.length > 0 && userRole && !allowedRoles.includes(userRole)) {
			return null
		}

		return <WrappedComponent {...(props as P)} />
	}

	return WithAuthComponent
}

export { withAuth }
