import type { LoginCredentials, RegisterUser } from '@/types'
import api from './api'

interface ForgotPasswordData {
	email: string
}

export const forgotPassword = async (data: ForgotPasswordData): Promise<void> => {
	await api.post('/auth/recover', data)
}

// Change password
export const changePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
	await api.post('/auth/change-password', {
		currentPassword,
		newPassword,
	})
}

export const resetPassword = async (token: string, password: string): Promise<void> => {
	await api.post(`/auth/resetPassword/${token}`, { password })
}

export const registerUser = async (data: RegisterUser): Promise<void> => {
	await api.post('/auth/register', data)
}

export const loginUser = async (data: LoginCredentials): Promise<{ token: string }> => {
	const response = await api.post<{ token: string }>('/auth/login', data)
	return response.data
}
