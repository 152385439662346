// src/components/ProtocolsList.tsx

'use client'

import { Card, CardHeader, CardTitle } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { GradientSpinner } from '@/components/ui/spinner'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { fetchProtocols, updateProtocolProgress } from '@/services/api'
import type { ProgramProtocol } from '@/types'
import { Calendar, Dumbbell, Files, UtensilsCrossed } from 'lucide-react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ProtocolsListProps {
	programId: number
	protocolType?: 'WORKOUT' | 'DIET' | 'OTHER'
}

const ProtocolsList: React.FC<ProtocolsListProps> = ({ programId, protocolType }) => {
	const { data: session } = useSession()
	const user = session?.user
	const { t } = useTranslation('protocols')
	const [protocols, setProtocols] = useState<ProgramProtocol[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<string | null>(null)
	const router = useRouter()
	const [updatingProtocolId, setUpdatingProtocolId] = useState<number | null>(null)
	const [selectedTab, setSelectedTab] = useState<'WORKOUT' | 'DIET' | 'OTHER'>(protocolType || 'WORKOUT')

	useEffect(() => {
		const getProtocols = async () => {
			try {
				const data = await fetchProtocols(programId)
				setProtocols(data)
			} catch (err) {
				console.error(err)
				setError(t('fetch_protocol_error'))
			} finally {
				setLoading(false)
			}
		}

		getProtocols()
	}, [programId, t])

	useEffect(() => {
		if (protocolType) {
			setSelectedTab(protocolType)
		}
	}, [protocolType])

	const handleCheckboxChange = async (protocolId: number, isChecked: boolean) => {
		try {
			setUpdatingProtocolId(protocolId)
			await updateProtocolProgress(programId, protocolId, isChecked)
			setProtocols((prev) =>
				prev.map((protocol) => (protocol.protocolId === protocolId ? { ...protocol, isChecked } : protocol)),
			)
		} catch (err) {
			console.error(err)
			setError(t('update_protocol_error'))
		} finally {
			setUpdatingProtocolId(null)
		}
	}

	const handleProtocolClick = (protocolId: number) => {
		router.push(`/protocols/${protocolId}/view`)
	}

	const renderProtocolCard = (pp: ProgramProtocol) => {
		const isCheckboxHidden = user?.role === 'COACH' || user?.role === 'ADMIN'

		return (
			<Card
				key={pp.id}
				className={`mb-3 bg-background dark:bg-darkGray border-1 shadow-md ${
					pp.isToday ? 'border-l-4 border-l-active-200' : ''
				}`}
			>
				<CardHeader className='flex flex-row items-center justify-between py-3'>
					<div>
						{!isCheckboxHidden && (
							<Checkbox
								id={`protocol-${pp.protocolId}`}
								checked={pp.isChecked}
								onCheckedChange={() => handleCheckboxChange(pp.protocolId, !pp.isChecked)}
								className='mr-3 border-muted data-[state=checked]:bg-active-200 data-[state=checked]:border-active-200'
								disabled={updatingProtocolId === pp.protocolId}
								aria-label={t('mark_protocol_as_completed', {
									protocolTitle: pp.protocol.title,
								})}
							/>
						)}
					</div>
					<button
						type='button'
						className='flex items-center flex-1'
						onClick={() => handleProtocolClick(pp.protocol.id)}
					>
						<div className='flex flex-col cursor-pointer'>
							<CardTitle className='text-base text-accent-foreground flex items-center'>
								{pp.protocol.title}
								{pp.isToday && (
									<span className='ml-2 text-xs bg-active-200 text-white px-2 py-1 rounded-full flex items-center'>
										<Calendar className='w-3 h-3 mr-1' />
										{t('today')}
									</span>
								)}
							</CardTitle>
							{pp.protocol.subtitle && <p className='text-sm text-muted'>{pp.protocol.subtitle}</p>}
						</div>
					</button>
				</CardHeader>
			</Card>
		)
	}

	if (loading) return <GradientSpinner />
	if (error) return <div className='p-4 text-destructive'>{error}</div>

	const workoutProtocols = protocols.filter((p) => p.protocol.type === 'WORKOUT')
	const dietProtocols = protocols.filter((p) => p.protocol.type === 'DIET')
	const otherProtocols = protocols.filter((p) => p.protocol.type === 'OTHER')

	return (
		<div className='relative'>
			<div>
				<div className='px-2 py-3 bg-accent/20 rounded-lg border-transparent'>
					<Tabs
						value={selectedTab}
						onValueChange={(value) => {
							if (value === 'WORKOUT' || value === 'DIET' || value === 'OTHER') {
								setSelectedTab(value)
							}
						}}
						className='w-full'
					>
						<div className='hidden sm:flex'>
							<TabsList className='grid w-full grid-cols-3 '>
								<TabsTrigger value='WORKOUT' className='flex items-center gap-2 '>
									<Dumbbell className='w-4 h-4' />
									{t('workouts')}
								</TabsTrigger>
								<TabsTrigger value='DIET' className='flex items-center gap-2'>
									<UtensilsCrossed className='w-4 h-4' />
									{t('diet')}
								</TabsTrigger>
								<TabsTrigger value='OTHER' className='flex items-center gap-2'>
									<Files className='w-4 h-4' />
									{t('other')}
								</TabsTrigger>
							</TabsList>
						</div>

						<TabsContent value='WORKOUT'>
							<div className='space-y-2'>
								<h3 className='text-lg font-semibold mb-4'>{t('workout_protocols')}</h3>
								{workoutProtocols.length > 0 ? (
									workoutProtocols.map(renderProtocolCard)
								) : (
									<p className='text-muted-foreground'>{t('no_workout_protocols')}</p>
								)}
							</div>
						</TabsContent>

						<TabsContent value='DIET'>
							<div className='space-y-2'>
								<h3 className='text-lg font-semibold mb-4'>{t('diet_protocols')}</h3>
								{dietProtocols.length > 0 ? (
									dietProtocols.map(renderProtocolCard)
								) : (
									<p className='text-muted-foreground'>{t('no_diet_protocols')}</p>
								)}
							</div>
						</TabsContent>

						<TabsContent value='OTHER'>
							<div className='space-y-2'>
								<h3 className='text-lg font-semibold mb-4'>{t('other_protocols')}</h3>
								{otherProtocols.length > 0 ? (
									otherProtocols.map(renderProtocolCard)
								) : (
									<p className='text-muted-foreground'>{t('no_other_protocols')}</p>
								)}
							</div>
						</TabsContent>
					</Tabs>
				</div>
			</div>

			{/* Tabs para Mobile */}
			<div className='fixed bottom-0 left-0 right-0 shadow-md h-16 sm:hidden'>
				<Tabs
					value={selectedTab}
					onValueChange={(value) => {
						if (value === 'WORKOUT' || value === 'DIET' || value === 'OTHER') {
							setSelectedTab(value)
						}
					}}
					className='w-full h-full'
				>
					<TabsList className='flex justify-between items-center h-full'>
						<TabsTrigger value='WORKOUT' className='flex flex-col items-center justify-center h-full w-full'>
							<Dumbbell className='w-6 h-6 mb-1' />
							<span className='text-xs'>{t('workouts')}</span>
						</TabsTrigger>
						<TabsTrigger value='DIET' className='flex flex-col items-center justify-center h-full w-full'>
							<UtensilsCrossed className='w-6 h-6 mb-1' />
							<span className='text-xs'>{t('diet')}</span>
						</TabsTrigger>
						<TabsTrigger value='OTHER' className='flex flex-col items-center justify-center h-full w-full'>
							<Files className='w-6 h-6 mb-1' />
							<span className='text-xs'>{t('other')}</span>
						</TabsTrigger>
					</TabsList>
				</Tabs>
			</div>
		</div>
	)
}

export { ProtocolsList }
