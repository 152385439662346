// src/components/protocol-items-table.tsx

'use client'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useToast } from '@/components/ui/use-toast'
import { deleteProtocolItem } from '@/services/api'
import type { ProtocolItemsTableProps } from '@/types'
import { Pencil, Trash2 } from 'lucide-react'
import type React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const ProtocolItemsTable: React.FC<ProtocolItemsTableProps> = ({
	protocolItems,
	setItems,
	setEditItem,
	isEditable = true,
}) => {
	const { toast } = useToast()
	const { t } = useTranslation('protocols')

	// Memorize the list of items sorted by id in ascending order
	const sortedProtocolItems = useMemo(() => {
		return [...protocolItems].sort((a, b) => a.id - b.id)
	}, [protocolItems])

	const handleDeleteItem = async (itemId: number) => {
		try {
			if (protocolItems.length === 0) throw new Error('No protocol items found.')
			const protocolId = protocolItems[0].protocolId

			await deleteProtocolItem(protocolId, itemId)

			toast({
				description: t('delete_protocol_success'),
				variant: 'success',
			})

			setItems(sortedProtocolItems.filter((item) => item.id !== itemId))
		} catch (error: any) {
			toast({
				description: error.response?.data?.message || t('an_error_occurred'),
				variant: 'destructive',
			})
		}
	}

	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead>{t('item')}</TableHead>
					<TableHead>{t('quantity')}</TableHead>
					<TableHead>{t('impact')}</TableHead>
					{isEditable && <TableHead>{t('action')}</TableHead>}
				</TableRow>
			</TableHeader>
			<TableBody>
				{sortedProtocolItems.map(({ id, item, quantity, impact }) => (
					<TableRow key={id}>
						<TableCell>{item}</TableCell>
						<TableCell>{quantity}</TableCell>
						<TableCell>{impact}</TableCell>
						{isEditable && (
							<TableCell className='flex space-x-2'>
								<button
									type='button'
									onClick={() =>
										setEditItem({
											id,
											item,
											quantity,
											impact,
											protocolId: protocolItems[0].protocolId,
										})
									}
									className='flex items-center'
								>
									<Pencil className='w-4 h-4 text-blue-500' />
								</button>
								<button type='button' onClick={() => handleDeleteItem(id)} className='flex items-center'>
									<Trash2 className='w-4 h-4 text-red-500' />
								</button>
							</TableCell>
						)}
					</TableRow>
				))}
			</TableBody>
		</Table>
	)
}

export default ProtocolItemsTable
