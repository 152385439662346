'use client'
import { getSelectStyles } from '@/utils/selectStyles'
import { useTheme } from 'next-themes'
import React from 'react'
import type { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import Select from 'react-select'

interface ControlledMultiSelectProps<T extends FieldValues> {
	field: ControllerRenderProps<T, FieldPath<T>>
	options: { value: string; label: string }[]
	placeholder?: string
}

export function ControlledMultiSelect<T extends FieldValues>({
	field,
	options,
	placeholder,
}: ControlledMultiSelectProps<T>) {
	const { theme = 'system' } = useTheme()

	return (
		<Select
			isMulti
			value={options.filter((option) => field.value?.includes(option.value))}
			onChange={(selectedOptions) => {
				const values = selectedOptions.map((option) => option.value)
				field.onChange(values)
			}}
			options={options}
			placeholder={placeholder}
			styles={getSelectStyles(theme)}
			onBlur={field.onBlur}
		/>
	)
}
