import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export const fadeIn = (direction: string, delay: any) => {
	return {
		hidden: {
			y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
			opacity: 0,
			x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
		},
		show: {
			y: 0,
			x: 0,
			opacity: 1,
			transition: {
				type: 'tween',
				duration: 1.2,
				delay: delay,
				ease: [0.25, 0.25, 0.25, 0.75],
			},
		},
	}
}

/**
 * Calculates the current week number based on a start date.
 * @param startDateString - The start date as a string (e.g., '2023-09-01').
 * @returns The current week number starting from 1.
 */
export const getCurrentWeek = (startDateString: string): number => {
	const startDate = new Date(startDateString)
	const currentDate = new Date()
	const diffTime = currentDate.getTime() - startDate.getTime()
	const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7))
	return diffWeeks + 1 // Starting from week 1
}
