import React from 'react'
interface TitleProps extends React.ComponentProps<'h1'> {
	children: React.ReactNode
}

const TitleComponent: React.FC<TitleProps> = ({ children, className }) => (
	<h1 className={`font-lora tracking-wide text-3xl font-bold py-4 ${className}`}>{children}</h1>
)

const Title = React.memo(TitleComponent)

// Definindo o displayName para satisfazer o linting e melhorar a depuração
Title.displayName = 'Title'

export { Title }
